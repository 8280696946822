import PropTypes from "prop-types";
import Link from 'next/link';

export { NavLink };

NavLink.propTypes = {
  href: PropTypes.string.isRequired,
  exact: PropTypes.bool,
};

// NavLink.defaultProps = {
//   exact: false,
// };

function NavLink({ children, href, exact=false, ...props  }:{
    [x:string]:any,
    children?:any,
    href:string,
    exact?:boolean
}):JSX.Element {
    //const { pathname } = useRouter();
    //const isActive = exact ? pathname === href : pathname.startsWith(href);
  
    // if (isActive) {
    //   props.className += " active";
    // }
    return (
    //   <Link href={href} legacyBehavior={true}>
        <a href={href} {...props}>{children}</a>
    // </Link>
    );
}